<template>
  <div class="pb-1">
    <!-- ANCHOR: SEARCH -->
    <Search
      class="mb-75"
      :default-search-data="payloadSearch"
      :is-disable="refLoading"
      :is-class-booking="true"
      @handleSearch="handleSearch"
    />

    <!-- ANCHOR - Ngày lân cận & bộ lọc mobile -->
    <BCard
      v-if="['xs', 'sm', 'md'].includes(appBreakPoint)"
      header-bg-variant="warning"
      header-class="p-0 rounded_header"
      class="rounded-lg mb-50 mb-lg-1 mt-50"
      no-body
    >
      <template #header>
        <BRow class="w-100 mx-0">
          <BCol
            cols="6"
            class="d-flex align-items-center text-white cursor-pointer border-right px-25 px-sm-1"
            @click="toggleVisibleCheapestHandle"
          >
            <span :class="`fw-700 mr-1 ${isMobileView ? 'font-small-4' : ''}`">
              {{ $t('flight.neighboringDays') }}
            </span>
            <div class="p-25">
              <IconSvg
                :src="require('@icons/triangle-down.svg')"
                blank-color="#ccc"
                class="py-25 rotate_class text-white"
                :style="{transform: rotateStyleCheapest, width: '18px'}"
              />
            </div>
          </BCol>
          <BCol
            cols="6"
            class="border-left d-flex align-items-center justify-content-end"
            @click="handleToggleFilter"
          >
            <span :class="`fw-700 text-white mr-1 ${isMobileView ? 'font-small-4' : ''}`">
              {{ $t('flight.filters') }}
            </span>
            <div class="p-25">
              <IconSvg
                :src="require('@icons/filter-white.svg')"
                blank-color="#ccc"
                class="fw-600 font-medium-2"
              />
            </div>
          </BCol>
        </BRow>
      </template>

      <BCollapse v-model="isVisibleCheapestFare">
        <div>
          <CheapFareSlideClassBooking class="mb-50" />
          <!-- @click="toggleVisibleCheapestHandle" -->
        </div>
      </BCollapse>
    </BCard>

    <BRow
      class="mt-1"
      no-gutters
    >
      <BCol
        cols="12"
        lg="8"
        xl="9"
        class="pr-lg-50"
      >
        <!-- ANCHOR - Month Cheapest -->
        <CheapFareSlideClassBooking v-if="['lg', 'xl'].includes(appBreakPoint) && isVisibleCheapestFare" />
        <!-- ANCHOR: TRIP RESULT -->
        <ResultSearchClassBooking
          @scrollToTarget="scrollToTarget"
          @scrollToCardShowPrice="scrollToCardShowPrice"
        />
      </BCol>
      <BCol
        cols="12"
        lg="4"
        xl="3"
        class="d-none d-lg-inline"
      >
        <div>
          <BCard
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            header-bg-variant="light-info"
            :class="`my-50 mx-25 border-info ${isCardCheapestFareDisabled ? 'cursor-not-allowed' : 'cursor-pointer'}`"
            header-class="py-25 px-25"
            no-body
            :disabled="isCardCheapestFareDisabled"
            @click="handleCardCheapestFareClick"
          >
            <template #header>
              <div
                v-if="!isCardCheapestFareDisabled"
                class="w-100 d-flex align-items-center justify-content-around flex-nowrap"
              >
                <b-img
                  class=""
                  :src="require('@icons/expland-left-double.svg') "
                  blank-color="#ccc"
                  width="21"
                />
                <span class="text-nowrap">{{ $t(`flight.${isVisibleCheapestFare ? 'hideCheapestFareSwiper' : 'showCheapestFareSwiper'}`) }}</span>
                <b-img
                  class=""
                  :src="require('@icons/date-info.svg') "
                  blank-color="#ccc"
                  width="21"
                />
              </div>
              <div
                v-else
                class="w-100 d-flex-center"
              >
                <b-spinner
                  small
                  variant="info"
                />
                <span class="text-info ml-25">{{ $t('flight.Processing') }}</span>
              </div>
            </template>
          </BCard>
        </div>
        <vue-perfect-scrollbar
          :settings="perfectScrollbarSettings"
          class="ps-customizer-area"
        >
          <!-- ANCHOR: FILTER -->
          <FilterClassBooking />
        </vue-perfect-scrollbar>
      </BCol>
    </BRow>

    <!-- ANCHOR: CREATE BOOKING -->
    <div ref="refReservationClassBooking">
      <SelectedTripClassBooking
        :get-search-flight-array="getSearchFlightArray"
        :selected-trips="selectedTrips"
      />
    </div>

    <BRow
      class="mx-0 rounded-lg mb-3 mb-lg-5"
      style="background-color: #F5F5F5"
    >
      <BCol
        cols="12"
        lg="6"
        :class="['xs','sm','md'].includes(appBreakPoint) ? 'px-0 py-50' : 'py-50 pl-50 pr-0'"
      >
        <!-- ANCHOR - Form đặt chỗ, BUTTON TÍNH GIÁ, GIỮ CHỖ -->
        <ReservationClassBooking @scroll-to-create-booking="handleSetWithCreatedBooking" />
      </BCol>

      <!-- SECTION - Tính giá, thông tin sau đặt chỗ -->
      <BCol
        cols="12"
        lg="6"
        class="py-1"
        :class="['xs','sm','md'].includes(appBreakPoint) ? 'px-0' : 'py-50 pr-50'"
      >
        <!-- ANCHOR - GIÁ TẠM TÍNH, GIÁ TÍNH LẠI -->
        <div>
          <CardShowPriceClassBooking :get-search-flight-array="getSearchFlightArray" />
        </div>

        <!-- ANCHOR - Thông tin sau đặt chỗ -->
        <div ref="refCreateBooking">
          <BCard
            :header-bg-variant="resBooking ? 'light-success' : errCreateBookingText ? 'light-danger' : 'light-info'"
            header-class="py-50 font-medium-2 font-weight-bolder text-airline"
            body-class="px-50 pt-50 pb-0"
            class="mt-50 border"
          >
            <template #header>
              {{ $t("flight['Reservation information']") }}
            </template>
            <template v-if="resBooking">
              <p class="text-success mt-50 mx-75">
                {{ $t("flight.createBookingSuccessful") }}
              </p>

              <hr class="mx-1">

              <div class="d-flex-between mx-75">
                <div class="d-flex-center">
                  <span class="mr-25">{{ $t('flight.bookingCode') }}:</span>
                  <BButton
                    class="px-1 py-50 py-lg-75 rounded-lg text-airline fw-700 font-medium-4"
                    variant="outline-info"
                    @click="handleGoToReservation(resBooking)"
                  >
                    {{ resBooking.pnr }}
                  </BButton>
                </div>
                <div class="d-flex-center">
                  <BButton
                    class="px-1 py-50 rounded-lg mr-1 font-weight-bolder"
                    variant="outline-info"
                    @click="goToTicketFace(resBooking)"
                  >
                    {{ $t('reservation.exportTicket') }}
                  </BButton>
                  <!-- <BButton
                      class="px-1 py-50 rounded-lg text-warning font-weight-bolder"
                      variant="outline-warning"
                    >
                      SMS
                    </BButton> -->
                </div>
              </div>

              <hr class="mx-1">

              <BTabs
                class="mb-1"
                pills
              >
                <BTab
                  :title="$t('flight.shortenBooking')"
                  active
                >
                  <ShortenBooking
                    :shorten-text="shortenBookingText"
                    :loading="loadingShortenBooking"
                    @get-shorten-booking-handle="getShortenBookingHandle"
                  />
                </BTab>
                <BTab
                  v-if="shortenBooking1A"
                  :title="$t('flight.shortenBooking1A')"
                >
                  <ShortenBooking
                    :shorten-text="shortenBooking1A"
                    :loading="loadingShortenBooking"
                    @get-shorten-booking-handle="getShortenBookingHandle"
                  />
                </BTab>
                <!-- <BTab :title="$t('flight.shortenBaggages')">
                  <ShortenBooking
                    :shorten-text="shortenBaggageText"
                    :loading="loadingShortenBooking"
                    @get-shorten-booking-handle="getShortenBookingHandle"
                  />
                </BTab> -->

                <template v-if="!isEmpty(dataFareRules)">
                  <BTab
                    v-for="(data, index) of dataFareRules"
                    :key="index"
                    :title="`${$t('flight.fareRule')}: ${data.trip[0].departure.iataCode}-${data.trip[data.trip.length - 1].arrival.iataCode}`"
                  >
                    <ShortenBooking
                      :shorten-text="data.fareRules"
                      :loading="loadingShortenFareRules"
                      @get-shorten-booking-handle="getShortenFareRulesHandle"
                    />
                  </BTab>
                </template>
              </BTabs>
            </template>
            <template v-else-if="errCreateBookingText">
              <div class="ml-1 mt-1 mb-2 font-italic text-danger fw-700">
                - {{ errCreateBookingText }}
              </div>
            </template>
            <template v-else>
              <div class="ml-1 mt-1 mb-2 font-italic text-danger">
                {{ $t('flight.noInformation') }}
              </div>
            </template>
          </BCard>
        </div>
      </BCol>
    </BRow>

    <!-- SidebarFilter -->
    <SidebarFilter
      v-if="['xs', 'sm', 'md'].includes(appBreakPoint)"
      v-model="isTaskHandlerSidebarActive"
    >
      <template #fee>
        <div>
          <FilterClassBooking />
        </div>
      </template>

      <template #filter>
        <div>
          <!-- <Filters /> -->
        </div>
      </template>
    </SidebarFilter>
  </div>
</template>

<script>
import {
  BCard,
  BCol,
  BRow,
  BButton,
  VBTooltip,
  BTabs,
  BTab,
  BCollapse,
  BSpinner,
  BImg,
} from 'bootstrap-vue'
import {
  ref, onBeforeMount, onUnmounted, computed, watch,
} from '@vue/composition-api'
import { useWindowSize } from '@vueuse/core'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

import VueI18n from '@/libs/i18n'
import { apiReservation, apiBooking } from '@/api'
import { SORT_ITINERARIES_TYPE } from '@/constants/flight'

import useBookingHandle from '@flightv2/useBookingHandle'
import storeModule from '@flightv2/bookingStoreModule'
import useClassBookingHandle from '@flightv2/result-class-booking/useClassBookingHandle'

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BButton,
    BTabs,
    BTab,
    BCollapse,
    BSpinner,
    BImg,
    VuePerfectScrollbar,

    Search: () => import('@flightv2/search/re-search/index.vue'),
    ResultSearchClassBooking: () => import('./components/ResultSearchClassBooking.vue'),
    ReservationClassBooking: () => import('./reservation/index.vue'),
    SelectedTripClassBooking: () => import('./components/SelectedTripClassBooking.vue'),
    FilterClassBooking: () => import('./components/FilterClassBooking.vue'),
    CardShowPriceClassBooking: () => import('./components/CardShowPriceClassBooking.vue'),
    SidebarFilter: () => import('@flightv2/result/SidebarFilter.vue'),
    ShortenBooking: () => import('./components/ShortenBooking.vue'),
    CheapFareSlideClassBooking: () => import('./components/CheapFareSlideClassBooking.vue'),
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  setup() {
    const {
      FLIGHT_APP_STORE_MODULE_NAME,
      getSearchFlightArray,
      fetchAirportGroup,
      searchClassBooking,
    } = useBookingHandle()
    const {
      store,
      router,
      cloneDeep,
      toastError,
      isEmpty,
      refLoading,
      airlineSearch,
      selectedTrips,
      status,
      formatCurrency,
      convertShortTrip,
      convertISODateTime,
      convertISODateTimeLangVN,
    } = useClassBookingHandle()
    if (!store.hasModule(FLIGHT_APP_STORE_MODULE_NAME)) {
      store.registerModule(FLIGHT_APP_STORE_MODULE_NAME, storeModule)
    }
    onUnmounted(() => {
      if (store.hasModule(FLIGHT_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(FLIGHT_APP_STORE_MODULE_NAME)
      }
    })
    if (isEmpty(store.getters['app-flight-v2/getAirportGroup'])) { fetchAirportGroup() }

    const payloadSearch = ref({ ...JSON.parse(localStorage.getItem('searchFlight')) })
    const customerData = ref(null)
    const priceCalculated = ref(null)
    const dataCalc = ref(null)

    const resBooking = computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getDataCreatedBookingByClassBooking`])
    const errCreateBookingText = computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getErrCreateBookingText`])

    function handleClickTab(index) {
      store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setTabIndex`, index)
    }

    function handleSearch(payload) {
      payloadSearch.value = { ...JSON.parse(localStorage.getItem('searchFlight')) }

      if (['1G'].includes(payload.airline)) store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setSortItinerariesType`, SORT_ITINERARIES_TYPE.BY_EARLIEST_DEPARTURE)

      searchClassBooking(payload)
        .then(() => {
          //
        })
        .catch(err => {
          toastError({
            title: 'flight.errLoadingFlight',
            content: err.message,
          })
          console.error('err:: searchClassBooking:: ', err)
        })
    }

    onBeforeMount(() => {
      handleSearch(payloadSearch.value)
    })

    const checkboxCustomerDataReplaceAgencyData = ref(false)
    const agencyData = ref({ ...store.getters['userStore/getMeData'] })
    function resetAgencyData() {
      agencyData.value = { ...store.getters['userStore/getMeData'] }
    }

    const loadingShortenBooking = ref(false)
    const loadingShortenFareRules = ref(false)

    const shortenBookingText = ref()
    const shortenBooking1A = ref()
    const shortenBaggageText = ref()
    const shortenFareRuleText = ref()
    const dataFareRules = ref(null)

    function getShortenBookingHandle() {
      loadingShortenBooking.value = true
      const source = airlineSearch.value

      if (['1G'].includes(source)) {
        const payload = {
          source: '1G',
          pnrNumber: resBooking.value.pnr,
        }

        apiReservation.shortenBooking(payload)
          .then(res => {
            shortenBookingText.value = res.booking ? res?.booking?.join('\n') || 'NO RECORD DATA' : res?.join('\n') || 'NO RECORD DATA'
            // shortenBaggageText.value = res.baggages ? res?.baggages?.join('\n') || 'NO RECORD DATA' : false
            // shortenFareRuleText.value = res.fareRules ? res?.fareRules?.join('\n') || 'NO RECORD DATA' : false
          })
          .finally(() => {
            loadingShortenBooking.value = false
          })
        return
      }

      const reservationDataInStore = resBooking.value
      let itinerariesText = ''
      reservationDataInStore.itineraries.forEach(trip => {
        trip.forEach(segment => {
          itinerariesText += `\t${convertShortTrip(segment)}\n`
        })
      })
      let paxsText = ''
      let numberTickets = ''
      reservationDataInStore.paxLists.forEach((pax, index) => {
        paxsText += `\t${index + 1}. ${pax.lastName} ${pax.firstName} ${pax.title || ''}\n`
        if (pax?.eticket?.length && reservationDataInStore.source === 'QH') {
          numberTickets += `\t${pax.lastName} ${pax.firstName} ${pax.title || ''}: ${pax.eticket.map(eticket => `${eticket.number}` || '').join('\n')}\n`
        }
      })
      const totalAmount = reservationDataInStore?.fareInfo?.total || reservationDataInStore?.totalAmountPaid + reservationDataInStore?.totalAmountToBePaid
      const locale = computed(() => VueI18n.locale).value
      shortenBookingText.value = `${locale === 'vi' ? 'Mã đặt chỗ' : 'Booking code'}: ${reservationDataInStore?.pnr || reservationDataInStore?.bookingCode}\n${locale === 'vi' ? 'Hành trình' : 'Itineraries'}:\n${itinerariesText}${locale === 'vi' ? 'Hành khách' : 'Passengers'}:\n${paxsText}${numberTickets ? `${locale === 'vi' ? 'Danh sách số vé' : 'Etickets'}:\n${numberTickets}` : ''}${locale === 'vi' ? 'Số điện thoại' : 'Phone'}: ${`${reservationDataInStore.contact?.paxInfo?.phone[0]?.prefix ? `${reservationDataInStore.contact?.paxInfo?.phone[0]?.prefix} ` : ''}`}${reservationDataInStore.contact?.paxInfo?.phone[0]?.address || reservationDataInStore.contact?.paxInfo?.phone[0]}\n${locale === 'vi' ? 'Email' : 'Email'}: ${reservationDataInStore.contact?.paxInfo?.email[0]?.address || reservationDataInStore.contact?.paxInfo?.email[0]}\n${totalAmount ? (locale === 'vi' ? 'TỔNG TIỀN' : 'TOTAL') : ''}: ${totalAmount ? formatCurrency(totalAmount) : ''}`

      if (['VN1A'].includes(source)) {
        shortenBooking1A.value = reservationDataInStore?.bookingImage || 'NO RECORD DATA'
      }

      loadingShortenBooking.value = false
    }

    async function getShortenFareRulesHandle() {
      loadingShortenFareRules.value = true
      const booking = resBooking.value

      try {
        dataFareRules.value = booking.itineraries.map((trip, indexTrip) => ({ trip, indexTrip, fareRules: null }))
        if (dataFareRules.value?.some(t => !t.fareRules)) {
          const promises = dataFareRules.value.map(async trip => {
            const payload = {
              source: booking.source,
              itinerary: trip.trip.map(segment => ({
                airline: segment?.airline || undefined,
                departDate: convertISODateTime(segment.departure.at, segment.departure.timeZone).ISOdatetime,
                departure: segment.departure.iataCode,
                arrival: segment.arrival.iataCode,
                fareBasisCode: '',
              })),
              pnrNumber: booking.pnr,
              contentSource: booking?.extra[0]?.contentSource,
            }
            const res = await apiBooking.getFareRules(payload)
            return {
              ...trip,
              fareRules: isEmpty(res) ? 'NO RECORD DATA' : res?.join('\n'),
            }
          })
          const results = await Promise.all(promises)
          results.forEach(data => {
            const index = dataFareRules.value.findIndex(i => i.indexTrip === data.indexTrip)
            const cloneData = cloneDeep(dataFareRules.value)
            cloneData[index] = data
            dataFareRules.value = cloneData
          })
        }
      } catch (error) {
        console.error(error)
      } finally {
        loadingShortenFareRules.value = false
      }
    }

    watch(() => resBooking.value, booking => {
      if (!booking) return
      getShortenBookingHandle()
      if (!['VN1A'].includes(airlineSearch.value)) {
        getShortenFareRulesHandle()
      }
    }, { deep: true })

    const handleGoToReservation = resBooking => {
      router.push({
        name: 'apps-reservations-modify',
        params: { id: resBooking.bookingId },
      })
    }
    const goToTicketFace = resBooking => {
      const { bookingId } = resBooking
      const path = `${bookingId}`
      const wrapHref = `/apps/bookings/${path}/ticket`
      window.open(wrapHref)
    }

    const refCreateBooking = ref(null)

    function handleSetWithCreatedBooking() {
      if (refCreateBooking.value) {
        window.scrollTo({
          // top: refCreateBooking.value.offsetTop,
          bottom: 0,
          behavior: 'smooth',
        })
      }
    }

    const { width } = useWindowSize()

    const refReservationClassBooking = ref(null)
    const scrollToTarget = () => {
      if (refReservationClassBooking.value) {
        // refReservationClassBooking.value.scrollIntoView({ behavior: 'smooth' })
        const targetPosition = refReservationClassBooking.value.offsetTop + (width < 768 ? 190 : 90)
        window.scrollTo({ top: targetPosition, behavior: 'smooth' })
      } else {
        window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
      }
    }

    function scrollToCardShowPrice() {
      scrollToTarget()
    }

    const isTaskHandlerSidebarActive = ref(false)

    function handleToggleFilter() {
      isTaskHandlerSidebarActive.value = !isTaskHandlerSidebarActive.value
    }

    const isVisibleCheapestFare = ref(true)
    const rotateStyleCheapest = computed(() => isVisibleCheapestFare.value ? 'rotate(0deg)' : 'rotate(-180deg)')

    const isCardCheapestFareDisabled = ref(false)

    const toggleVisibleCheapestHandle = () => {
      isVisibleCheapestFare.value = isVisibleCheapestFare.value !== true
      localStorage.setItem('visibleCheapestFare', isVisibleCheapestFare.value)
    }

    const handleCardCheapestFareClick = () => {
      if (!isCardCheapestFareDisabled.value) {
        toggleVisibleCheapestHandle()

        isCardCheapestFareDisabled.value = true
        setTimeout(() => {
          isCardCheapestFareDisabled.value = false
        }, 1200)
      }
    }
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: true,
    }

    return {
      perfectScrollbarSettings,
      refLoading,
      scrollToTarget,
      refReservationClassBooking,
      scrollToCardShowPrice,
      width,
      airlineSearch,
      resetAgencyData,
      agencyData,
      checkboxCustomerDataReplaceAgencyData,
      customerData,
      getShortenFareRulesHandle,
      dataFareRules,
      loadingShortenFareRules,

      payloadSearch,
      selectedTrips,

      isEmpty,
      formatCurrency,
      convertISODateTime,
      convertISODateTimeLangVN,

      handleClickTab,
      priceCalculated,
      getSearchFlightArray,
      status,
      dataCalc,
      handleSearch,

      resBooking,
      loadingShortenBooking,
      shortenBookingText,
      shortenBooking1A,
      shortenBaggageText,
      shortenFareRuleText,
      getShortenBookingHandle,

      errCreateBookingText,
      handleGoToReservation,
      goToTicketFace,

      handleSetWithCreatedBooking,
      refCreateBooking,

      isTaskHandlerSidebarActive,
      handleToggleFilter,

      isVisibleCheapestFare,
      rotateStyleCheapest,
      toggleVisibleCheapestHandle,
      handleCardCheapestFareClick,
      isCardCheapestFareDisabled,
    }
  },
  computed: {
    tabIndex: {
      get() { return this.$store.getters['app-flight-v2/getTabIndex'] },
      set(newValue) { this.$store.dispatch('app-flight-v2/setTabIndex', newValue) },
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
// #status_select ::v-deep {
//   .vs__actions {
//     display: none;
//   }

//   .vs__search {
//     height: 33px;
//   }

//   .vs__dropdown-menu {
//     max-width: 80px !important;
//   }
// }

// .class_booking_select_form {
//     border-left: 4px solid transparent !important;
// }

// .active_class_booking_select {
//   border-left: 4px solid #efad02 !important;
//   background-color: antiquewhite !important;
// }

.btn-scroll-to-top {
  position: fixed;
  top: 20%;
  right: 30px;
  z-index: 99;

  opacity: 0;
  // transform: translateY(100px);
  transition: all .5s ease;

    // width < md
  @media (max-width: 768px) {
    right: 10px;
    bottom: 8%;
  }

  &.show {
    opacity: 0.6;
    // transform: translateY(0)
    &:hover {
      opacity: 1;
    }
  }
}

.ps-customizer-area {
  max-height: calc(75vh);
}
</style>
